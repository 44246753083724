<template>
  <div>
    <clinicList
      :canEdit="true"
      editRoute="FranchiseClinicEdit"
      :canCreate="true"
      createRoute="FranchiseClinicCreate"
      :canViewDetail="true"
      :canViewClinicTreatmentHistory="true"
      clinicTreatmentHistoryRoute="FranchiseClinicTreatmentHistory"
    />
  </div>
</template>

<script>
import clinicList from "../../components/clinics/clinicList.vue";

export default {
  components: {
    clinicList
  },
  data: () => ({})
};
</script>